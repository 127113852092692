import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Make from "./components/Make";
import ProtectedRouteForPart from "./components/Route/SpecialUsers/ProtectedRouteForPart";
import Header from "./components/HeaderFooter/Header";
import FooterCom from "./components/HeaderFooter/FooterCom";
import OnlyAdminPrivateRoute from "./components/Route/OnlyAdminPrivateRoute";
import PrivateRoute from "./components/Route/PrivateRoute";
import ReactGA from "react-ga4";
import ProtectedRouteForSpecial from "./components/Route/SpecialUsers/ProtectedRouteForSpecial";
import Loading from "./components/Misc/Loading";
import CEC from "./pages/admin/Demo/CEC";
import Certificate from "./pages/admin/Demo/Certificate";
import Upload from "./pages/admin/Restricted/Upload";
import MarketPlace from "./pages/marketplace/MarketPlace";
import ProductsPage from "./components/marketPlace/ProductsPage";
import CheckoutPage from "./pages/marketplace/CheckoutPage";
import QRTarget from "./pages/admin/Demo/QRTarget";

const PrivacyPolicy = React.lazy(() => import("./pages/Misc/PrivacyPolicy"));
const ScheduleForm = React.lazy(
  () => import("./components/forms/Lessons/ScheduleForm")
);
const Graphs = React.lazy(() => import("./pages/admin/Restricted/Graphs"));

const ArtForm = React.lazy(() => import("./components/forms/Lessons/ArtForm"));
const ArtInstitute = React.lazy(
  () => import("./pages/admin/Managements/ArtInstitute")
);
const ItSolutions = React.lazy(
  () => import("./components/forms/professional/ItSolutions")
);
const IT = React.lazy(() => import("./pages/admin/professional/IT"));
const InfluencersForm = React.lazy(
  () => import("./components/forms/influencers/InfluencersForm")
);
const InfluencersListing = React.lazy(
  () => import("./pages/listings/InfluencersListing")
);
const Influencers = React.lazy(() => import("./pages/admin/Influencers"));
const Home = React.lazy(() => import("./pages/Home"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const MainReview = React.lazy(() => import("./pages/listings/MainReview"));
const Advertisement = React.lazy(() => import("./pages/admin/Advertisement"));
const Restaurants = React.lazy(() => import("./pages/admin/Restaurants"));
const NotFound = React.lazy(() => import("./components/Route/NotFound"));
const ToursForm = React.lazy(() => import("./components/forms/ToursForm"));
const GetListed = React.lazy(() => import("./pages/users/GetListed"));
const ListOfServices = React.lazy(
  () => import("./pages/listings/ListsOfServices")
);
const Tours = React.lazy(() => import("./pages/admin/Tours"));
const ClinicForm = React.lazy(() => import("./components/forms/ClinicForm"));
const HealthCare = React.lazy(() => import("./pages/users/HealthCare"));
const Health = React.lazy(() => import("./pages/admin/Health"));
const DentalClinicForm = React.lazy(
  () => import("./components/forms/DentalClinicForm")
);
const Dentist = React.lazy(() => import("./pages/admin/Dentist"));
const ListOfServicesIndustry = React.lazy(
  () => import("./pages/listings/ListOfServicesIndustry")
);
const MainReviewIndustry = React.lazy(
  () => import("./pages/listings/MainReviewIndustry")
);
const ListedTable = React.lazy(() => import("./pages/admin/Listings"));
const CAForm = React.lazy(
  () => import("./components/forms/professional/CAForm")
);
const CA = React.lazy(() => import("./pages/admin/professional/CA"));
const Page = React.lazy(() => import("./pages/admin/Restricted/Page"));
const Details = React.lazy(() => import("./pages/admin/Restricted/Details"));
const Constituency = React.lazy(
  () => import("./pages/admin/Restricted/Constituency")
);
const RestaurantForm = React.lazy(
  () => import("./components/forms/RestaurantForm")
);
const TRACKING_ID = "G-KJ91VJL30M";
ReactGA.initialize("G-KJ91VJL30M");

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return null; // This component doesn't need to render anything
};

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Analytics />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tours" element={<ToursForm />} />
          <Route path="/market" element={<MarketPlace />} />
          <Route path="/market/cart" element={<CheckoutPage />} />
          <Route path="/market/:productId" element={<ProductsPage />} />
          <Route path="/influencers" element={<InfluencersListing />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/sikkim-food/:resName"
            element={<MainReview reviewFor="restaurants" />}
          />
          {/* <Route path="/upload" element={<Upload />} /> */}
          <Route
            path="/tours-travels/:resName"
            element={<MainReview reviewFor="tours" />}
          />
          <Route
            path="/health-care/:resName"
            element={<MainReview reviewFor="health" />}
          />{" "}
          <Route path="/get-listed" element={<GetListed />} />
          <Route
            path="/chartered-accountant/:resName"
            element={
              <MainReviewIndustry
                category="finance"
                industry="professional-services"
                businessType="chartered-accountant"
              />
            }
          />
          <Route
            path="/dental-clinic/:resName"
            element={
              <MainReviewIndustry
                category="clinics"
                industry="healthcare"
                businessType="dentist"
              />
            }
          />
          <Route
            path="/recreational-activities/:resName"
            element={
              <MainReviewIndustry
                category="art"
                industry="education"
                businessType="art"
              />
            }
          />
          <Route
            path="/it/:resName"
            element={
              <MainReviewIndustry
                category="it"
                industry="professional-services"
                businessType="it"
              />
            }
          />
          <Route
            path="/sikkim-food"
            element={
              <ListOfServices serviceType="restaurants" link="sikkim-food" />
            }
          />{" "}
          <Route
            path="/dental-clinic"
            element={
              <ListOfServicesIndustry
                businessType="dentist"
                industry="healthcare"
                category="clinics"
                link="dental-clinic"
              />
            }
          />
          <Route
            path="/tours-travels"
            element={
              <ListOfServices serviceType="tours" link="tours-travels" />
            }
          />
          <Route
            path="/chartered-accountant"
            element={
              <ListOfServicesIndustry
                businessType="chartered-accountant"
                industry="professional-services"
                category="finance"
                link="chartered-accountant"
              />
            }
          />
          <Route
            path="/it"
            element={
              <ListOfServicesIndustry
                businessType="it"
                industry="professional-services"
                category="it"
                link="it"
              />
            }
          />{" "}
          <Route
            path="/recreational-activities"
            element={
              <ListOfServicesIndustry
                businessType="art"
                industry="education"
                category="art"
                link="recreational-activities"
              />
            }
          />
          <Route
            path="/health-care"
            element={<ListOfServices serviceType="health" link="health-care" />}
          />
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/get-listed/restaurants"
              element={<RestaurantForm />}
            />
            <Route path="/get-listed/tours" element={<ToursForm />} />
            <Route path="/get-listed/sch" element={<ScheduleForm />} />
            <Route path="/get-listed/art" element={<ArtForm />} />
            <Route
              path="/get-listed/influencers"
              element={<InfluencersForm />}
            />
            <Route
              path="/get-listed/dental-clinic"
              element={<DentalClinicForm />}
            />
            <Route path="/get-listed/professionals" element={<CAForm />} />
            <Route path="/get-listed/health-care" element={<HealthCare />} />
            <Route path="/get-listed/it" element={<ItSolutions />} />
            <Route
              path="/get-listed/health-care/clinic"
              element={<ClinicForm />}
            />
          </Route>
          <Route element={<OnlyAdminPrivateRoute />}>
            <Route path="/graph" element={<Graphs />} />
            <Route
              path="/constituency/:cons/:part/:page/:id"
              element={<Details />}
            />
            <Route path="/constituency/:cons/:part/:page" element={<Page />} />
            {/* <Route path="/constituency/:cons/:part" element={<PageNo />} /> */}
            <Route path="/constituency" element={<Constituency />} />
            <Route
              element={<RestaurantForm />}
              path="/dashboard/restaurants/update/:resName"
            />{" "}
            <Route
              element={<ArtForm />}
              path="/dashboard/art/update/:resName"
            />
            <Route
              element={<ToursForm />}
              path="/dashboard/tours/update/:resName"
            />
            <Route path="/cec" element={<CEC />} />
            <Route path="/cec/:detailId" element={<QRTarget />} />
            <Route path="/cec/:id" element={<Certificate />} />
            <Route
              element={<CAForm />}
              path="/dashboard/chartered-accountant/update/:resName"
            />
            <Route
              element={<ItSolutions />}
              path="/dashboard/it/update/:resName"
            />
            <Route
              element={<InfluencersForm />}
              path="/dashboard/influencers/update/:resName"
            />{" "}
            {/* <Route path="/salghari/graphs" element={<Graphs />} /> */}
            <Route
              element={<ClinicForm />}
              path="/dashboard/health/update/:resName"
            />
            {/* <Route path="/make" element={<Make />} /> */}
            <Route
              element={<DentalClinicForm />}
              path="/dashboard/dentist/update/:resName"
            />
            <Route element={<Health />} path="/dashboard/health" />
            <Route element={<Influencers />} path="/dashboard/influencers" />
            <Route element={<ArtInstitute />} path="/dashboard/art" />
            <Route element={<Dentist />} path="/dashboard/dentist" />
            <Route element={<Restaurants />} path="/dashboard/restaurants" />
            <Route element={<Tours />} path="/dashboard/tours" />
            <Route element={<CA />} path="/dashboard/chartered-accountant" />
            <Route element={<IT />} path="/dashboard/it" />
            <Route
              path="/dashboard/advertisement"
              element={<Advertisement />}
            />
            <Route
              path="/dashboard/listing-requests"
              element={<ListedTable />}
            />
          </Route>
          <Route element={<ProtectedRouteForSpecial />}>
            <Route path="/constituency" element={<Constituency />} />
          </Route>
          <Route element={<ProtectedRouteForPart />}>
            <Route
              path="/constituency/:cons/:part/:page/:id"
              element={<Details />}
            />
            <Route path="/constituency/:cons/:part/:page" element={<Page />} />
            {/* <Route path="/constituency/:cons/:part" element={<PageNo />} /> */}
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>{" "}
      </Suspense>

      <FooterCom />
    </BrowserRouter>
  );
}

export default App;
